var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"analysis-table"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data,"options":_vm.options,"loading":_vm.loading},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"200px"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"cut-text"},[_vm._v(_vm._s(item.key))])])])]}},{key:"item.sumAmount.net",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.sumAmount.net))+" ")])]}},{key:"item.sumAmount.vat",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.sumAmount.vat))+" ")])]}},{key:"item.sumAmount.gross",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.sumAmount.gross))+" ")])]}},{key:"item.averageOfAmountPerDay.net",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.averageOfAmountPerDay.net))+" ")])]}},{key:"item.averageOfAmountPerDay.vat",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.averageOfAmountPerDay.vat))+" ")])]}},{key:"item.averageOfAmountPerDay.gross",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.averageOfAmountPerDay.gross))+" ")])]}},{key:"item.quantityOfOrders",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"disable-line-breaks"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.quantityOfOrders))+" ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t('SYSTEM_TOTALS')))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('sumAmountNet'))))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('sumAmountVat'))))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('sumAmountGross'))))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('quantityOfOrders'))))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('averageOfOrdersPerDay'))))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('averageOfAmountPerDayNet'))))]),_c('td',{staticClass:"subtitle-2 text-center"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumField('averageOfAmountPerDayGross'))))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }