







































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DataOptions, DataTableHeader} from 'vuetify';
import {IDashboardAnalysis, IDashboardAnalysisSumAmount} from '@/types/IDashboardTypes';
import {getLocalStorage, setLocalStorage} from '@/utils/localStorage';

@Component({name: 'DashboardAnalysisTable'})
export default class DashboardAnalysisTable extends Vue {
  @Prop()
  type!: string;

  @Prop()
  data!: IDashboardAnalysis[];

  @Prop()
  loading!: boolean;

  @Prop()
  setInfoBlock!: boolean;

  options: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  }

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_KEY',
      value: 'key',
      align: 'center'
    },
    {
      text: 'SYSTEM_SUM_AMOUNT_NET',
      value: 'sumAmount.net',
      align: 'center',
    },
    {
      text: 'SYSTEM_SUM_AMOUNT_VAT',
      value: 'sumAmount.vat',
      align: 'center',
    },
    {
      text: 'SYSTEM_SUM_AMOUNT_BRUTTO',
      value: 'sumAmount.gross',
      align: 'center',
    },
    {
      text: 'SYSTEM_QUANTITY_OF_ORDERS',
      value: 'quantityOfOrders',
      align: 'center',
    },
    {
      text: 'SYSTEM_AVERAGE_OF_ORDERS_PER_DAY',
      value: 'averageOfOrdersPerDay',
      align: 'center',
    },
    {
      text: 'SYSTEM_AVERAGE_OF_AMOUNT_PER_DAY_NET',
      value: 'averageOfAmountPerDay.net',
      align: 'center',
    },
    // {
    //   text: 'SYSTEM_AVERAGE_OF_AMOUNT_PER_DAY_VAT',
    //   value: 'averageOfAmountPerDay.vat',
    //   align: 'end',
    // },
    {
      text: 'SYSTEM_AVERAGE_OF_AMOUNT_PER_DAY_BRUTTO',
      value: 'averageOfAmountPerDay.gross',
      align: 'center',
    },
  ];

  updateOptions(options: DataOptions) {
    this.options = options
    this.saveTableOptionsInLocalStorage()
  }

  saveTableOptionsInLocalStorage() {
    setLocalStorage('dashboardAnalysisTable', this.options)
  }

  getTableOptionsFromLocalStorage() {
    const options = getLocalStorage('dashboardAnalysisTable')
    if (options) {
      this.options = options
    }
  }

  sumField(key: string) {
    const data: IDashboardAnalysisSumAmount[] = this.data;

    data.forEach((el) => {
      el.sumAmountNet = el.sumAmount.net;
      el.sumAmountVat = el.sumAmount.vat;
      el.sumAmountGross = el.sumAmount.gross;
      el.averageOfAmountPerDayNet = el.averageOfAmountPerDay.net;
      // el.averageOfAmountPerDayVat = el.averageOfAmountPerDay.vat;
      el.averageOfAmountPerDayGross = el.averageOfAmountPerDay.gross;
    });

    // @ts-ignore
    return data.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
  }

  created() {
    this.getTableOptionsFromLocalStorage()
  }

  formatNumber(value: number) {
    return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // return formatNumberWithSpaces(Math.round(value), 2);
  }
}
